function sliderA1() {
    const componentClass = 'o-slider-a-1';
    let slidersContainers = document.querySelectorAll(
        `.${componentClass}__sliders-container`,
    );
    slidersContainers.forEach((slidersContainer) => {
        const textSlider = slidersContainer.querySelector(
            `.${componentClass}__slider-container--text .swiper-container`,
        );
        const imageSlider = slidersContainer.querySelector(
            `.${componentClass}__slider-container--image .swiper-container`,
        );

        if (!textSlider.swiper || !imageSlider.swiper) {
            return;
        }

        const textSliderSwiper = textSlider.swiper;
        const imageSliderSwiper = imageSlider.swiper;
        imageSliderSwiper.params.on = {
            init: updateSlideTitle(imageSliderSwiper),
        };
        textSliderSwiper.params.on = {
            init: updateSlideTitle(textSliderSwiper),
        };

        imageSliderSwiper.update();
        textSliderSwiper.update();

        imageSliderSwiper.on('slideChange', function () {
            updateSlideTitle(this);
            textSliderSwiper.slideTo(this.activeIndex);
        });
        textSliderSwiper.on('slideChange', function () {
            updateSlideTitle(this);
            imageSliderSwiper.slideTo(this.activeIndex);
        });
    });

    function updateSlideTitle(sliderSwiper) {
        let currentSlideIndex = sliderSwiper.activeIndex;
        let currentSlide = sliderSwiper.slides[currentSlideIndex];
        if (!currentSlide) {
            return;
        }
        let slideTitle = currentSlide.getAttribute('data-title') || '';
        let titleElement = sliderSwiper.el.querySelector(
            `[data-role='slide-title']`,
        );
        if (!titleElement) {
            return;
        }
        titleElement.innerHTML = slideTitle;
    }
}

export default sliderA1;
