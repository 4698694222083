class FilterTypeOne {
    constructor(block) {
        this.container = block;

        this._initProps();
        this._initElements();
        this._initStates();
        this._initListeners();
    }

    /**
     * All needed parameters of the block are set up here
     *
     * @private
     */
    _initProps() {
        // Main organism's class
        this.componentClass = 'o-filter-1';

        // Block Top offset
        this.blockOffsetTop = 70;

        // Data from backend
        // URL for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxUrl = filterTypeOne.url;
        // Name of the action
        // eslint-disable-next-line no-undef
        this.ajaxAction = filterTypeOne.action;
        // Nonce code for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxNonce = filterTypeOne.nonce;

        // Current data of the filters form
        this.formData = {};

        // Does the form includes submit button. Affects the pills functionality
        this.includesSubmitButton = null;
        // Are we currently waiting for AJAX response?
        this.isProcessingAjax = false;
    }

    /**
     * All DOM elements within the main Block which we are going to use are set up here
     *
     * @private
     */
    _initElements() {
        // Form
        this.filtersForm = this.container.querySelector(
            `[data-role='filters-form']`,
        );
        // Search Input
        this.searchInput = this.container.querySelector(`input[name='search']`);
        // Taxonomies multiselect dropdown
        this.termsDropdowns = this.container.querySelectorAll(
            `select[data-role='multiselect-dropdown']`,
        );
        // Taxonomies dropdown pills
        this.termsDropdownPillsContainer = this.container.querySelector(
            `[data-role='dropdown-pills-container']`,
        );
        // Taxonomies pills
        this.termsPills = this.container.querySelectorAll(
            `[data-role='terms-pills']`,
        );
        // Taxonomies Pills 'Clear All' buttons
        this.termsPillsClearButtons = this.container.querySelectorAll(
            `[data-role='terms-clear-all']`,
        );
        // Taxonomies Select
        this.termsSelects = this.container.querySelectorAll(
            `[data-role='terms-select']`,
        );
        // Form Clear Button
        this.formClearButton = this.container.querySelector(
            `[data-role='clear-form']`,
        );
        // Submit Button
        this.submitButton = this.container.querySelector(
            `input[data-role='submit-form']`,
        );
        // The main container of posts
        this.filterList = this.container.querySelector(
            `[data-role='posts-list']`,
        );
        // Page Input
        this.pageInput = this.container.querySelector(`[name='page']`);
        // Is Pagination input. Helps to distinguish pagination query from other queries
        this.paginationInput = this.container.querySelector(
            `[name='isPagination']`,
        );
        // Pagination Container
        this.paginationBlock = this.container.querySelector(
            `[data-role='pagination-block']`,
        );
        // Total posts count input
        this.totalPostsCountInput = this.container.querySelector(
            `[name='totalPostsCount']`,
        );
    }

    /**
     * Set certain states for elements on class init
     *
     * @private
     */
    _initStates() {
        // Check whether form includes submit button or not
        this.includesSubmitButton = Boolean(this.submitButton);
        // Update the initial form data
        this._updateFormData();
        // Render pills for dropdowns if they exist and 'Dropdown + pills' option is chosen
        if (this.termsDropdownPillsContainer) {
            this._dropdownPillsUpdate();
        }
    }

    /**
     * All events listeners are listed here
     *
     * @private
     */
    _initListeners() {
        // Form Submit button
        if (this.includesSubmitButton) {
            this.submitButton.addEventListener(
                'click',
                this._submitButtonHandler.bind(this),
            );
        }
        // Terms Dropdown Functionality
        if (this.termsDropdowns.length) {
            [...this.termsDropdowns].map((select) => {
                $(select).multiselect({
                    columns: select.dataset.placeholder
                        ? parseInt(select.dataset.placeholder)
                        : 1,
                    search: parseInt(select.dataset.search) === 1,
                    selectAll:
                        parseInt(select.getAttribute('data-select-all')) === 1,
                    showCheckbox: parseInt(select.dataset.checkbox) === 1,
                    texts: {
                        placeholder: select.dataset.placeholder,
                        selectAll: select.getAttribute('data-select-all-text'),
                    },
                    // eslint-disable-next-line no-unused-vars
                    onOptionClick: (select, input) => {
                        this._termsDropdownHandler();
                    },
                    onSelectAll: () => {
                        this._termsDropdownHandler();
                    },
                });
                select.addEventListener(
                    'change',
                    this._termsDropdownHandler.bind(this),
                );
            });
        }
        // Terms Dropdown Pills
        if (this.termsDropdownPillsContainer) {
            this.termsDropdownPillsContainer.addEventListener(
                'click',
                this._dropdownPillsHandler.bind(this),
            );
        }
        // Terms Pills Functionality
        if (this.termsPills.length) {
            [...this.termsPills].map((pillsContainer) => {
                const inputs = pillsContainer.querySelectorAll(
                    'input[type="checkbox"]',
                );
                if (!inputs.length) {
                    return false;
                }
                inputs.forEach((input) => {
                    input.addEventListener(
                        'change',
                        this._termsPillsHandler.bind(this),
                    );
                });
            });
        }
        // Terms Pills 'Clear All' functionality
        if (this.termsPillsClearButtons.length) {
            [...this.termsPillsClearButtons].map((button) => {
                button.addEventListener(
                    'click',
                    this._termsPillsClearAllHandler.bind(this),
                );
            });
        }
        // Clear Form button
        if (this.formClearButton) {
            this.formClearButton.addEventListener(
                'click',
                this._clearFormButtonHandler.bind(this),
            );
        }
        // Pagination
        if (this.paginationBlock) {
            this.paginationBlock.addEventListener(
                'click',
                this._paginationHandler.bind(this),
            );
        }
    }

    /**
     * Form Submit button click event handler
     *
     * @param event
     * @private
     */
    _submitButtonHandler(event) {
        // Preventing default behaviour
        event.preventDefault();

        // On form submitting we need to see the first page
        this.pageInput.value = 1;
        // Sending AJAX request
        this._requestPosts();
    }

    /**
     * Terms Dropdown changes handler
     *
     * @private
     */
    _termsDropdownHandler() {
        // Update Dropdown pills states
        if (this.termsDropdownPillsContainer) {
            this._dropdownPillsUpdate();
        }

        // Do nothing if we have the form Submit button
        if (this.includesSubmitButton) {
            return;
        }

        // On form submitting we need to see the first page
        this.pageInput.value = 1;
        // Sending AJAX request
        this._requestPosts();
    }

    /**
     * Pills handler
     *
     * @param event
     * @private
     */
    _termsPillsHandler(event) {
        // Do nothing when AJAX is processing
        if (this.isProcessingAjax) {
            event.preventDefault();
            return;
        }
        // Related Input
        const input = event.target;
        // Checked state after change
        const isChecked = input.checked;

        // Otherwise change UI for the current pill according to its new value
        this._updatePillState(input, isChecked);

        if (!this.includesSubmitButton) {
            this.pageInput.value = 1;
            // Sending AJAX request for getting posts
            this._requestPosts();
        }
    }

    /**
     * Select/Deselect all the checkboxes within a container
     *
     * @param container
     * @param isChecked
     * @private
     */
    _selectAllPills(container, isChecked = true) {
        if (!container) {
            return;
        }
        // All the checkbox that are need to be updated
        const inputs = container.querySelectorAll(`input[type='checkbox']`);
        [...inputs].map((input) => {
            // Change the input state
            input.checked = isChecked;
            this._updatePillState(input, isChecked);
        });
    }

    /**
     * 'Clear All' button functionality
     *
     * @param event
     * @private
     */
    _termsPillsClearAllHandler(event) {
        // Current Clear All Button
        const button = event.target;
        // Container with the related pills
        const pillsContainer = button.closest(`[data-role='terms-pills']`);

        // Select all the pills
        this._selectAllPills(pillsContainer, false);

        // If no submit button - send ajax request
        if (!this.includesSubmitButton) {
            this.pageInput.value = 1;
            this._requestPosts();
        }
    }

    /**
     * Changing a pill UI according to it's state (active or not)
     *
     * @param input
     * @param isChecked
     * @private
     */
    _updatePillState(input, isChecked = true) {
        if (!input) {
            return;
        }
        const label = input.closest('label');
        if (!label) {
            return;
        }
        if (isChecked) {
            label.classList.remove('text-[#F05F50]', 'bg-white');
            label.classList.add('text-white', 'bg-[#F05F50]');
        } else {
            label.classList.remove('text-white', 'bg-[#F05F50]');
            label.classList.add('text-[#F05F50]', 'bg-white');
        }
    }

    /**
     * Collect the current form data and store them in the Class's parameter
     *
     * @private
     */
    _updateFormData() {
        let formData = new FormData(this.filtersForm);
        formData.append('action', this.ajaxAction);
        formData.append('_nonce', this.ajaxNonce);

        // For dealing with multiselect values we need to filter it
        const data = {};
        formData.forEach(function (value, key) {
            const prev = data[key];
            if (prev) {
                data[key] = `${prev}, ${value}`;
            } else {
                data[key] = value;
            }
        });

        this.formData = data;
    }

    /**
     * Sending AJAX request with parameters for getting posts list output
     *
     * @private
     */
    _requestPosts() {
        // Update current form data before sending it to backend
        this._updateFormData();

        // eslint-disable-next-line jquery/no-ajax
        $.ajax({
            type: 'POST',
            url: this.ajaxUrl,
            data: this.formData,
            beforeSend: () => {
                this.isProcessingAjax = true;
                // Show loader
                this._updateLoaderState();
            },
            success: (response) => {
                // New output from backend (posts list)
                let postsListOutput = response.data.postsOutput;
                if (!postsListOutput) {
                    return;
                }
                // Update the whole posts list container
                this.filterList.innerHTML = postsListOutput;

                // Updating the total posts count list
                if (
                    response.data.totalPostsCount !== undefined &&
                    this.totalPostsCountInput
                ) {
                    this.totalPostsCountInput.value = parseInt(
                        response.data.totalPostsCount,
                    );
                }

                if (!this.paginationBlock) {
                    return;
                }
                // Pagination Block
                let paginationBlockOutput = response.data.paginationBlockOutput;
                if (!paginationBlockOutput) {
                    return;
                }

                // Update the whole pagination block container
                this.paginationBlock.innerHTML = paginationBlockOutput;
            },
            // eslint-disable-next-line no-unused-vars
            complete: (xhr) => {
                this.isProcessingAjax = false;
                // Hide loader
                this._updateLoaderState(false);
                if (this.paginationInput) {
                    this.paginationInput.value = 0;
                }
                // Scroll to the top of the block
                this._scrollToBlockTop();
            },
            error: (xhr) => {
                console.log('error:', xhr);
            },
        });
    }

    /**
     * Show Loader above the main filter block. show = true - show loader; show = false - hide loader
     *
     * @param show
     * @private
     */
    _updateLoaderState(show = true) {
        if (show) {
            this.container.classList.add('opacity-50', 'pointer-events-none');
        } else {
            this.container.classList.remove(
                'opacity-50',
                'pointer-events-none',
            );
        }
    }

    /**
     * Pagination functionality
     *
     * @param event
     * @private
     */
    _paginationHandler(event) {
        event.preventDefault();

        const target = event.target;
        const button = target.closest(`[data-role='pagination-button']`);
        if (!button) {
            return;
        }

        const page = parseInt(button.getAttribute('data-value'));
        if (isNaN(page)) {
            return;
        }

        this.pageInput.value = page;
        if (this.paginationInput) {
            this.paginationInput.value = 1;
        }
        this._requestPosts();
    }

    /**
     * Catch clicks on pills container and update dropdowns selected options list
     *
     * @param event
     * @private
     */
    _dropdownPillsHandler(event) {
        // If we didn't click on a pill do nothing
        if (!event.target.closest(`[data-role='term-dropdown-pill']`)) {
            return;
        }
        // Otherwise preventing default behaviour
        event.preventDefault();

        // Current clicked pill
        const pill = event.target;
        // Term Name
        const name = pill.getAttribute('data-term');
        // Term ID of this pill
        const value = pill.getAttribute('data-value');
        // Looking for the related dropdown
        const relatedDropdown = this.container.querySelector(
            `select[name='${name}']`,
        );
        if (!relatedDropdown) {
            return;
        }
        // Looking for the related option within the found dropdown
        const relatedOption = relatedDropdown.querySelector(
            `option[value='${value}']`,
        );
        if (!relatedOption) {
            return;
        }
        // Unselect this option
        relatedOption.selected = false;
        // Updating multiselect for correct UI
        $(relatedDropdown).multiselect('reload');
        // Trigger event
        relatedDropdown.dispatchEvent(new Event('change'));
    }

    /**
     * Looping through the all terms select and re-render pills for selected options
     *
     * @private
     */
    _dropdownPillsUpdate() {
        if (!this.termsDropdowns.length || !this.termsDropdowns.length) {
            return;
        }
        // New inner of the pills container
        let result = '';

        // Looping through the all terms selects
        [...this.termsDropdowns].map((select) => {
            // Current Term slug
            const term_slug = select.getAttribute('name');
            // All the options of this select
            const options = select.querySelectorAll('option');
            if (!options.length) {
                return false;
            }

            // Looping through the all options
            [...options].map((option) => {
                if (option.selected) {
                    result += this._getDropdownPillElement(
                        term_slug,
                        option.value,
                        option.innerText,
                    );
                }
            });
        });
        // Updating inner of the pills container
        this.termsDropdownPillsContainer.innerHTML = result;
    }

    /**
     * Renders a pill for dropdown + pills option
     *
     * @param term_slug
     * @param term_id
     * @param term_name
     * @returns {string}
     * @private
     */
    _getDropdownPillElement(term_slug = '', term_id = '', term_name = '') {
        if (!term_slug || !term_id || !term_name) {
            return '';
        }

        return `
            <button
                type="button"
                title="Unselect"
                class="${this.componentClass}__dropdown-pill flex justify-center items-center cursor-pointer w-auto px-3 py-1 rounded-lg border border-[#F05F50] border-solid text-base"
                data-term="${term_slug}"
                data-role="term-dropdown-pill"
                data-value="${term_id}">
                ✕ ${term_name}
            </button>
        `;
    }

    /**
     * Clear All button functionality
     *
     * @param event
     * @private
     */
    _clearFormButtonHandler(event) {
        event.preventDefault();

        // Clear Search input field
        if (this.searchInput) {
            this.searchInput.value = '';
        }

        // Reset terms Dropdowns
        if (this.termsDropdowns.length) {
            [...this.termsDropdowns].map((select) => {
                // All the options
                const options = select.querySelectorAll('option');
                if (!options.length) {
                    return false;
                }
                // Unselect all the options
                [...options].map((option) => {
                    option.selected = false;
                });
                // Trigger change event on this select
                select.dispatchEvent(new Event('change'));
                // Re-render multiselect element
                $(select).multiselect('reload');
            });
        }

        // Clear Pills
        if (this.termsPills.length) {
            [...this.termsPills].map((pillsContainer) => {
                this._selectAllPills(pillsContainer, false);
            });
        }
    }

    /**
     * Smooth Scrolling to the top of the block. Better to use in mobile mode
     * @private
     */
    _scrollToBlockTop() {
        let bodyOffset = document.body.getBoundingClientRect().top;
        let blockOffset = this.container.getBoundingClientRect().top;

        let neededOffset = blockOffset - bodyOffset - this.blockOffsetTop;
        neededOffset = neededOffset > 0 ? neededOffset : 0;

        window.scrollTo({
            top: neededOffset,
            behavior: 'smooth',
        });
    }
}

function filterType1() {
    const filterBlocks = document.querySelectorAll(
        `[data-role='filter-block-1']`,
    );
    if (!filterBlocks.length) {
        return;
    }
    filterBlocks.forEach((block) => {
        // eslint-disable-next-line no-unused-vars
        const blockClass = new FilterTypeOne(block);
    });
}

export default filterType1;
